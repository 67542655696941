import React from "react"
import { graphql } from "gatsby"
import loadable from '@loadable/component'

const Index = loadable(() => import('../components/home'))

// import Home from '../components/home'

// const LoadableHome = ({ data, location }) => {
//   return Loadable({
//     loader: ({ data, location }) => import('../components/home'),
//     loading() {
//       return <div>Loading...</div>
//     }
//   })
// }

const LoadableHome = ({ data, location }) => {
  return <Index data={data} location={location} />
}

export default LoadableHome

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            type
            category
            description
            data
            date
          }
        }
      }
    }
  }
`
